import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as GlobalElements from "../components/global/global.styles"

const customComponents = {
  h1: GlobalElements.H1,
  h2: GlobalElements.H2,
  h3: GlobalElements.H3,
  p: GlobalElements.Paragraph,
  a: GlobalElements.ExternalLink,
  hr: GlobalElements.HR,
  blockquote: GlobalElements.Quote,
  pre: props => <pre {...props} />,
  code: props => <code {...props} />,
  figcaption: GlobalElements.Caption,
}

const { Link } = GlobalElements
const shortcodes = { Link }

const Post = ({ data: { mdx } }) => {
  const { title, excerpt } = mdx.frontmatter
  return (
    <MDXProvider components={{ ...customComponents, ...shortcodes }}>
      <SEO title={title} description={excerpt} />
      <Layout>
        <article>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </article>
      </Layout>
    </MDXProvider>
  )
}

export default Post

export const pageQuery = graphql`
  query Post($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        excerpt
      }
    }
  }
`
